import React, {useEffect, useState} from 'react';
import './Product.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartCirclePlus, faCreditCard, faStarHalfStroke} from "@fortawesome/pro-regular-svg-icons";
import {faStar} from "@fortawesome/pro-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import ProductType from "../../Types/ProductType";
import axios from "axios";
import {addToCart} from "../cart/addToCart";
import ProductsHome from "../../components/Home/ProductsHome";
import ProductsRecommended from '../cart/ProductsRecommended';
import Cookies from "universal-cookie";
import CartCookieType from "../../Types/CartCookieType";
import {toast} from "sonner";

function Home() {
    const {id} = useParams();
    const [product, setProduct] = useState<ProductType | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get("https://shopapi.deagarys.com/api/Products/GetByPrettyLink",
            {params: {
                prettyLink: id
            }})
        .then(res => {
            setProduct(res.data);
        }).catch((e) => navigate("/notfound"));
    }, [id]);

    function buyNow(e: React.MouseEvent<HTMLElement>, stock: number | undefined) {
        const productId = e.currentTarget.dataset["id"];

        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie");

        if (cartCookie === undefined || cartCookie === "") {
            let newCartCookie: CartCookieType[] = [{id: productId, amount: 1}];
            cookies.set("cartCookie", encodeURI(JSON.stringify(newCartCookie)), {secure: true, path: "/"});
            return;
        }

        let cartCookieObject = JSON.parse(decodeURI(cartCookie));
        let productExistsInCart = cartCookieObject.find((element: CartCookieType) => {
            return element.id === productId
        });

        // add message already is in cart
        if (productExistsInCart) {
            toast.error('Product already is in cart');
            return;

            // if (stock && ((productExistsInCart.amount * 1) + 1) > stock)
            //     return;
            //
            // productExistsInCart.amount = (productExistsInCart.amount * 1) + 1;
        } else {
            cartCookieObject.push({id: productId, amount: 1});
        }

        toast.success('Product added to cart');
        cookies.set("cartCookie", encodeURI(JSON.stringify(cartCookieObject)), {secure: true, path: "/"});
        navigate("/cart");
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"product-wrapper max-w-l"}>
                <div className={"product-images"}>
                    <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(product?.imagePath ? product.imagePath : "")}`} alt="" className={"border-radius"}/>
                    {/*<div className="sub-images flex gap-5">*/}
                    {/*    <div className="sub-image border-radius"></div>*/}
                    {/*    <div className="sub-image border-radius"></div>*/}
                    {/*    <div className="sub-image border-radius"></div>*/}
                    {/*    <div className="sub-image border-radius"></div>*/}
                    {/*</div>*/}
                </div>
                <div className={"product-info"}>
                    <h1>{product?.name}</h1>
                    <h2>{product?.subCategories[0].category.name} / {product?.subCategories[0].name}</h2>
                    <p className={"description"}>{product?.description ? product.description : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aperiam autem excepturi illum iusto laborum maxime minus, pariatur tenetur ullam! Animi asperiores eveniet ex ipsam iste libero necessitatibus nesciunt provident."}</p>
                    <div className="price-wrapper flex items-center gap-3">
                        <p className={"price"}>&euro;{product?.price.toFixed(2).replace(".", ",").replace(",00", ",-")}</p>
                        {product?.stock && product?.stock > 0 ? (
                            <span className={"item-in-stock"}>In stock</span>
                        ) : (
                            <span className={"item-out-stock"}>Out of stock</span>
                        )}
                    </div>
                    <div className={"flex gap-5 items-center"}>
                        <button className={"button-action border-radius"} onClick={(e) => addToCart(e, product?.stock)} data-id={product?.id} disabled={(!(product?.stock && product.stock > 0))}><FontAwesomeIcon icon={faCartCirclePlus} /> Add to cart</button>
                        <p>or</p>
                        <button className={"button-action border-radius"} onClick={(e) => buyNow(e, product?.stock)} data-id={product?.id} disabled={(!(product?.stock && product.stock > 0))}><FontAwesomeIcon icon={faCreditCard} />Buy now</button>
                    </div>
                    <div className="rating flex gap-3">
                        {/*<div className="stars">*/}
                        {/*    <FontAwesomeIcon icon={faStar} style={{color: "#FFD43B",}} />*/}
                        {/*    <FontAwesomeIcon icon={faStar} style={{color: "#FFD43B",}} />*/}
                        {/*    <FontAwesomeIcon icon={faStar} style={{color: "#FFD43B",}} />*/}
                        {/*    <FontAwesomeIcon icon={faStar} style={{color: "#FFD43B",}} />*/}
                        {/*    <FontAwesomeIcon icon={faStarHalfStroke} style={{color: "#FFD43B",}} />*/}
                        {/*</div>*/}
                        {/*<div>43 Reviews</div>*/}
                    </div>
                </div>
            </div>
            <div className={"max-w-l products-recommendations"}>
                <ProductsRecommended exceptId={id} maxPerPage={4} />
            </div>
        </div>
    );
}

export default Home;