import React, {useEffect, useState} from 'react';
import Sidebar from "../../../components/Navbar/Sidebar";
import "../Admin.css";
import Modal from 'react-modal';
import AddProductModal from "../Products/AddProductModal";
import OrdersDashboardOverview from './OrdersDashboardOverview';

interface OptionsType {
    value: string,
    label: string
}

interface SubCategoryIds {
    subCategoryId: string
}

function OrdersDashboard() {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {

    }, [modalIsOpen]);

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper main-wrapper-admin">
            <Sidebar />
            <div className={"admin-dashboard-wrapper"}>
                <OrdersDashboardOverview />
            </div>
        </div>
    );
}

export default OrdersDashboard;