import React, {useEffect, useState} from 'react';
import Sidebar from "../../components/Navbar/Sidebar";
import "./Admin.css";

function Dashboard() {
    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper main-wrapper-admin">
            <Sidebar />
            <div className={"admin-dashboard-wrapper"}>
                Content
            </div>
        </div>
    );
}

export default Dashboard;