import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {OrderType} from "../../orders/Orders";
import {numberFormatSummary} from "../../../scripts/NumberFormat";
import CustomModal from "../../../components/CustomModal";
import {Link} from "react-router-dom";

function CategorysDashboardOverview() {
    const [data, setData] = useState<OrderType[]>([]);
    const [openOrderModal, setOpenOrderModal] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderType>();
    const [modalTitle, setModalTitle] = useState("");
    const cookies = new Cookies();

    useEffect(() => {
        axios.get(`https://shopapi.deagarys.com/api/Orders`, { headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`} })
            .then(res => {
                const result = res.data;
                setData(result);
            });

    }, [order, modalTitle, openOrderModal]);

    function closeModal() {
        setOpenOrderModal(false);
    }

    function viewOrder(e: React.MouseEvent<HTMLButtonElement>) {
        axios.get(`https://shopapi.deagarys.com/api/Orders/${e.currentTarget.dataset["id"]}`, { headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`} })
            .then(res => {
                setOrder(res.data.value);
                setModalTitle(res.data.value.orderNumber);
                setOpenOrderModal(true);
            }).catch((e) => e);
    }

    return (
        <div className={"categories-wrapper w-full"}>
            <div className={"categories-table"}>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className={"ext-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
                    <tr>
                        <th>Order Number</th>
                        <th>Order Date</th>
                        <th>User Email</th>
                        <th>Mollie Status</th>
                        <th>Total</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((data, index) => (
                        <tr key={index} className={"odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"}>
                            <td className={""}>{data.orderNumber}</td>
                            <td className={""}>{new Date(data.orderDate).toLocaleDateString()}</td>
                            <td className={""}>{data.user.email}</td>
                            <td className={""}>{data.mollieStatus}</td>
                            <td className={""}>{numberFormatSummary(data.subTotal)}</td>
                            <td className={"flex flex-row gap-2"}>
                                <button className={"border-radius button-action"} data-id={data.id} onClick={viewOrder}>View</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <CustomModal isOpen={openOrderModal} title={modalTitle} modalClose={closeModal}>
                <div>
                    {order?.orderProducts ? order.orderProducts.map((data, index) => (
                        <div>
                            <p>{data.product.name} - {data.amount}</p>
                            <Link to={`/product/${data.product.prettyLink}`} target={"_blank"}>View product</Link>
                        </div>
                    )) : ""}
                    <p>{order?.deliveryAddress.streetName}</p>
                    <p>{order?.deliveryAddress.streetNumber}</p>
                    <p>{order?.deliveryAddress.city}</p>
                    <p>{order?.deliveryAddress.province}</p>
                    <p>{order?.deliveryAddress.zipCode}</p>
                    <p>{order?.deliveryAddress.country}</p>
                </div>
            </CustomModal>
        </div>
    );
}

export default CategorysDashboardOverview;