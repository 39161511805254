import Cookies from 'universal-cookie';
import {toast} from "sonner";

export async function Register(values: {}) {
    const res = await fetch('https://shopapi.deagarys.com/authentication/register', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
    });

    if (res.status != 200) {
        return await res.text();
    }

    toast.success('Account created, please check email to verify');
    return "true";
}