import React, {useEffect, useState} from "react";
import {faCartShopping} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductType from "../../../Types/ProductType";
import axios from "axios";
import {numberFormat} from "../../../scripts/NumberFormat";
import {Link, useNavigate} from "react-router-dom";
import CustomModal from "../../../components/CustomModal";
import { createRef, Component } from 'react';
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Select from "react-select";
import Cookies from "universal-cookie";
import SubCategoryType from "../../../Types/SubCategoryType";
import {Styles} from "react-modal";
import {useCookies} from "react-cookie";
import {ProductRegion, ProductTypeType} from "../../../Types/ProductRegion";

type InputsUpdate = {
    productName: string,
    prettyLink: string,
    description: string,
    price: number,
    stock: number,
    type: OptionsType,
    region: OptionsType,
    image: string,
    subCategoryId: OptionsType[]
};

interface OptionsType {
    value: string,
    label: string
}

interface SubCategoryIds {
    subCategoryId: string
}

const optionsRegion = [
    { value: '0', label: 'Japanese' },
    { value: '1', label: 'English' }
]

const optionsType = [
    { value: '0', label: 'Booster' },
    { value: '1', label: 'Single' }
]

function ProductsDashboardOverview(props: {
    addedProduct: boolean; style?: Styles | undefined,
}) {
    const [data, setData] = useState<ProductType[] | []>([]);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [modalTitle, setModalTitle] = React.useState("");
    const {register, resetField, control, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<InputsUpdate>();
    const [options, setOptions] = useState<OptionsType[]>([]);
    const [subCategories, setSubcategories] = useState<SubCategoryType[]>([]);
    const [product, setProduct] = useState<ProductType>();
    const [productName, setProductName] = useState<"">();
    const [productDeleted, setProductDeleted] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {

        axios.get(`https://shopapi.deagarys.com/api/products`)
            .then(res => {
                const result = res.data;
                setData(result);
            });

        axios.get("https://shopapi.deagarys.com/api/SubCategories")
            .then(res => {
                let options: OptionsType[] = [];
                res.data.forEach((item: SubCategoryType) => {
                    options.push({value: item.id, label: item.name});
                })
                setOptions(options);
                setSubcategories(res.data);
            })

        setProductDeleted(false);
    }, [product, modalIsOpen, props.addedProduct, productDeleted]);

    const onSubmit: SubmitHandler<InputsUpdate> = async (dataUpdate) => {
        if (product === undefined)
            return;

        var formData = new FormData();
        formData.append("Id", product.id);
        formData.append("Name", dataUpdate.productName);
        formData.append("Description", dataUpdate.description);
        formData.append("Price", dataUpdate.price.toString().replace(",", "."));
        formData.append("Stock", dataUpdate.stock.toString());
        formData.append("PrettyLink", dataUpdate.prettyLink);

        if (dataUpdate.region) {
            formData.append("Region", dataUpdate.region.value);
        }

        if (dataUpdate.type) {
            formData.append("Type", dataUpdate.type.value);
        }

        if (dataUpdate.subCategoryId !==  undefined) {
            dataUpdate.subCategoryId.forEach((item, index) => {
                formData.append(`SubCategoryIds[${index}]`, item.value);
            });
        }

        if (dataUpdate.image.length !== 0) {
            formData.append("Image", dataUpdate.image[0]);
        }

        const cookies = new Cookies();
        await axios({
            method: "put",
            url: `https://shopapi.deagarys.com/api/Products/${product?.id}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data > 0) {
                    closeModal();

                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    function editProduct(e: React.MouseEvent<HTMLButtonElement>) {
        axios.get(`https://shopapi.deagarys.com/api/Products/${e.currentTarget.dataset["id"]}`)
            .then(res => {
                resetField("productName");
                resetField("price");
                resetField("prettyLink");
                resetField("description");
                resetField("stock");
                resetField("subCategoryId");
                resetField("image");
                setModalTitle(res.data.name)
                setIsOpen(true);
                setProduct(res.data);

            }).catch((e) => e);
    }

    function deleteProduct(e: React.MouseEvent<HTMLButtonElement>) {
        const cookies = new Cookies();
        axios.delete(`https://shopapi.deagarys.com/api/Products/${e.currentTarget.dataset["id"]}`, { headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`} })
            .then(res => {
                setProductDeleted(true);
            }).catch((e) => e);
    }

    return (
        <div className={"products-wrapper"}>
            <div className={"products-table"}>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className={"ext-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
                        <tr>
                            <th>Name</th>
                            <th>SubCategory</th>
                            <th>Region</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Stock</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {data.map((product, index) => (
                        <tr key={index} className={"odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"}>
                            <td className={""}>{product.name}</td>
                            <td className={""}>{product.subCategories[0].name}</td>
                            <td className={""}>{ProductRegion[product.region]}</td>
                            <td className={""}>{ProductTypeType[product.type]}</td>
                            <td className={"text-right"}>€{numberFormat(product.price)}</td>
                            <td className={"text-right"}>{product.stock}</td>
                            <td className={"flex flex-row gap-2"}>
                                <button className={"border-radius button-action"} data-id={product.id} onClick={editProduct}>Edit</button>
                                <button className={"border-radius button-action button-delete"} data-id={product.id} onClick={deleteProduct}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <CustomModal modalClose={closeModal} isOpen={modalIsOpen} title={modalTitle}>
                    <form onSubmit={handleSubmit(onSubmit)} className={`form-control`}>
                        <div className={""}>
                            <input defaultValue={product?.name} {...register("productName", {
                                required: "Name is required"
                            })} className={`border-radius ${errors.productName ? "border-red" : ""}`} placeholder={"Product name"}/>
                        </div>
                        {errors.productName && (<span className={"text-red-500"}>{errors.productName.message}</span>)}
                        <div className={""}>
                            <input defaultValue={product?.prettyLink} {...register("prettyLink", {
                                required: "prettyLink is required"
                            })} className={`border-radius ${errors.productName ? "border-red" : ""}`} placeholder={"prettyLink name"}/>
                        </div>
                        {errors.prettyLink && (<span className={"text-red-500"}>{errors.prettyLink.message}</span>)}
                        <div className={""}>
                            <input defaultValue={product?.description} {...register("description", {
                                required: "description is required"
                            })} className={`border-radius ${errors.description ? "border-red" : ""}`} placeholder={"Description"}/>
                        </div>
                        {errors.prettyLink && (<span className={"text-red-500"}>{errors.prettyLink.message}</span>)}
                        <div className={""}>
                            <input defaultValue={product?.price} {...register("price", {
                                required: "Price is required",
                                pattern: {
                                    value: /^\d+(.\d{1,2})?$/,
                                    message: "Price must be valid"
                                },
                            })} type={"text"} className={`border-radius ${errors.price ? "border-red" : ""}`} placeholder={"Product price"}/>
                        </div>
                        {errors.price && (<span className={"text-red-500"}>{errors.price.message}</span>)}
                        <div className={""}>
                            <input defaultValue={product?.stock} {...register("stock", {
                                required: "Stock is required"
                            })} className={`border-radius ${errors.stock ? "border-red" : ""}`} placeholder={"Product stock"}/>
                        </div>
                        {errors.stock && (<span className={"text-red-500"}>{errors.stock.message}</span>)}
                        <div className={""}>
                            <Controller
                                control={control}
                                name={"region"}
                                render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                                    <Select  onChange={onChange} name={name} options={optionsRegion} isMulti={false} />
                                )}
                            />
                        </div>
                        <div className={""}>
                            <Controller
                                control={control}
                                name={"type"}
                                render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                                    <Select  onChange={onChange} name={name} options={optionsType} isMulti={false} />
                                )}
                            />
                    </div>
                        <div className={""}>
                            <input {...register("image")} type={"file"} className={`border-radius ${errors.image ? "border-red" : ""}`} placeholder={"Product image"}/>
                        </div>
                        {errors.image && (<span className={"text-red-500"}>{errors.image.message}</span>)}
                        <div className={""}>
                            <Controller
                                control={control}
                                name={"subCategoryId"}
                                render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                                    <Select defaultValue={product?.subCategories.map(e => e)} getOptionLabel={ x => x.name}
                                            getOptionValue={ x => x.id} onChange={onChange} name={name} options={subCategories} isMulti={true} />
                                )}
                            />
                        </div>
                        {errors.subCategoryId && (<span className={"text-red-500"}>{errors.subCategoryId.message}</span>)}
                        <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Updating..." : "Update Product"}</button>
                    </form>
                </CustomModal>
            </div>
        </div>
    );
}

export default ProductsDashboardOverview;