import React, {useEffect, useState} from 'react';
import Sidebar from "../../../components/Navbar/Sidebar";
import "../Admin.css";
import UsersDashboardOverview from "./UsersDashboardOverview";

type Inputs = {
    productName: string,
    prettyLink: string,
    price: number,
    stock: number,
    image: string,
    subCategoryId: OptionsType[]
};

interface OptionsType {
    value: string,
    label: string
}

interface SubCategoryIds {
    subCategoryId: string
}

function UsersDashboard() {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {

    }, [modalIsOpen]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper main-wrapper-admin">
            <Sidebar />
            <div className={"admin-dashboard-wrapper"}>
                <UsersDashboardOverview  />
            </div>
        </div>
    );
}

export default UsersDashboard;