import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Cookies from "universal-cookie";
import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import axios from "axios";
import ProductType from "../../Types/ProductType";
import "./Orders.css";
import {faFileInvoice} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {capitalize} from "../../scripts/Capitalize";
import CartCookieType from "../../Types/CartCookieType";
import {numberFormatSummary} from "../../scripts/NumberFormat";
import {Address} from "../cart/Cart";

export interface OrderType {
    id: string,
    orderNumber: string,
    orderProducts: OrderProductType[] | null,
    orderDate: Date,
    completed: boolean,
    completedDate: Date | null,
    mollieStatus: string | null,
    subTotal: number,
    vat: number,
    user: UserType,
    deliveryAddress: Address
}

interface UserType {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    username: string
}

interface OrderProductType {
    product: ProductType,
    order: OrderType,
    amount: number
}

function Orders() {
    let { id } = useParams();
    const [orderStatus, setOrderStatus] = useState("incomplete");
    const [order, setOrder] = useState<OrderType>();
    const cookies = new Cookies();

    function fetchOrder() {
        const cookies = new Cookies();
        axios.get(`https://shopapi.deagarys.com/api/Orders/GetByOrderNumber/${id}`, { headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`} })
            .then(res => {
                setOrder(res.data.value);
            }).catch((e) => e);
    }

    const connection = new HubConnectionBuilder()
        .withUrl('https://shopapi.deagarys.com/orderHub')
        .withAutomaticReconnect([0, 2000, 10000, 30000])
        .configureLogging(LogLevel.Critical)
        .build();

    connection.start()
        .then(async () => {
            await connection.invoke("AddToGroup", id);
        })
        .catch(err => console.error('Error connecting to hub:', err));

    connection.on('ReceiveMessage', message => {
    });

    connection.on("OrderStatusChange", message => {
        orderCompleted(message);
    });

    function orderCompleted(message: string) {
        setOrderStatus(message)
        fetchOrder();
    }

    useEffect(() => {
        emptyCart();
        fetchOrder();
    }, [id, orderStatus]);


    function emptyCart() {
        let cartCookie = cookies.get("cartCookie")
        let cartCookieObject: CartCookieType[] = JSON.parse(decodeURI(cartCookie));

        while (cartCookieObject.length > 0) {
            cartCookieObject.pop();
        }

        cookies.set("cartCookie", encodeURI(JSON.stringify(cartCookieObject)), {secure: true, path: "/"});
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"orders-wrapper max-w-l"}>
                <div className={"order-status border-radius"}>
                    <h1><FontAwesomeIcon icon={faFileInvoice} /> {order?.completed ? "Order placed!" : "Processing Order."}</h1>
                    <p>Order number: <b>{id}</b></p>
                    <p>Status: <b>{order?.mollieStatus ? capitalize(order.mollieStatus) : "Awaiting payment"}</b></p>
                    <p>Confirmation email will be sent upon payment. (Might be in spam)</p>
                </div>

                <div className={"products"}>
                    {order?.orderProducts?.map((product, index) => (
                        <div key={index} className={"order-product"}>
                            <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(product.product.imagePath)}`} alt=""/>
                            <div>
                                <p>Name:</p>
                                <p>{product.product.name}</p>
                            </div>
                            <div>
                                <p>Price:</p>
                                <p>{numberFormatSummary(product.product.price)}</p>
                            </div>
                            <div>
                                <p>Amount:</p>
                                <p>{product.amount}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Orders;