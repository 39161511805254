import React, {useEffect, useState} from 'react'
import Cookies from 'universal-cookie';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faCartShopping, faUser, faTable} from "@fortawesome/pro-light-svg-icons";
import {useCookies} from "react-cookie";
import CartCookieType from "../../Types/CartCookieType";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../state/store";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {signOut} from "../../state/user/loggedInSlice";
import {clearUserData} from "../../state/user/userSlice";
import {toast} from "sonner";

export default function NavbarMobile () {
    const auth = useSelector((state: RootState) => state.persisted.auth.value);
    const user = useSelector((state: RootState) => state.userPersisted.user.value);
    const [cookies] = useCookies(['cartCookie'])
    const [cartAmount, setCartAmount] = useState<number>(0);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {

        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie");
        if (cartCookie === undefined || cartCookie === "")
            return;

        let carCookieObject: CartCookieType[] = JSON.parse(decodeURI(cartCookie));
        let amount = 0;
        carCookieObject.forEach((element) => {
            amount += (element.amount * 1);
        });

        setCartAmount(amount);
    }, [cookies]);

    return (
        <nav className={"mobile"}>
            <div className={"nav-wrapper max-w-l"}>
                <div className={"identity"}>
                    <img src="/menu.svg" alt="" onClick={handleClick} className={"cursor-pointer"} />
                    <Link to={"/"} onClick={() => setIsOpen(false)}>
                        <div className={"shop-name"}>Nerdy Relics</div>
                    </Link>
                </div>
                <Link to="/cart" onClick={() => setIsOpen(false)}><FontAwesomeIcon icon={faCartShopping} />
                    {cartAmount > 0 ? <span className={"cart-badge"}>{cartAmount}</span> : ""}
                </Link>
            </div>
            <div className={`mobile-menu ${isOpen ? 'menu-open' : 'menu-closed'}`}>
                <ul>
                    <li>
                        <Link to="/" onClick={handleClick}>Home</Link>
                    </li>
                    {/*<li>*/}
                    {/*    <Link to="/" onClick={handleClick}>Categories</Link>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                    {/*    <Link to="/" onClick={handleClick}>Contact</Link>*/}
                    {/*</li>*/}
                    { auth ?
                        <div>
                            <p className={"text-gray-500 italic"}>Welcome {user ? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] : ""}!</p>
                            {(user && user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "Admin") ? (
                                <li><NavLink to="/admin/dashboard" onClick={handleClick}><FontAwesomeIcon icon={faTable} />Admin Panel</NavLink></li>
                            ) : ""}
                            <li><Link to="/account" onClick={handleClick}><FontAwesomeIcon icon={faUser} />Account</Link></li>
                            <li><Link to="/" onClick={() => {
                                const cookies = new Cookies();
                                dispatch(signOut());
                                dispatch(clearUserData());
                                cookies.remove("_jwt");
                                toast.success('Logged out');
                                navigate("/");
                                handleClick();
                            }}><FontAwesomeIcon icon={faArrowRightFromBracket} /> Logout</Link></li>
                        </div>
                        :
                        <div>
                            <li>
                                <Link to="/account/login" onClick={handleClick}>Login</Link>
                            </li>
                            <li>
                                <Link to="/account/register" onClick={handleClick}>Register</Link>
                            </li>
                        </div>
                    }
                </ul>
            </div>
        </nav>
    )
}