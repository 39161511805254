import React, {useEffect, useState} from "react";
import Cookies from "universal-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavbarSearch from "./NavbarSearch";
import {faCartShopping, faUser} from "@fortawesome/pro-light-svg-icons";
import CartCookieType from "../../Types/CartCookieType";
import {useCookies} from "react-cookie";
import {useSignals} from "@preact/signals-react/runtime";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../state/store";
import {signOut} from "../../state/user/loggedInSlice";
import {NavLink, useNavigate} from "react-router-dom";
import {clearUserData} from "../../state/user/userSlice";
import {toast} from "sonner";

export default function NavbarDesktop () {
    const [cookies] = useCookies(['cartCookie'])
    const [cartAmount, setCartAmount] = useState<number>(0);
    const auth = useSelector((state: RootState) => state.persisted.auth.value);
    const user = useSelector((state: RootState) => state.userPersisted.user.value);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    useSignals();
    useEffect(() => {
        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie");
        if (cartCookie === undefined || cartCookie === "")
            return;

        let carCookieObject: CartCookieType[] = JSON.parse(decodeURI(cartCookie));
        let amount = 0;
        carCookieObject.forEach((element) => {
            amount += (element.amount * 1);
        });

        setCartAmount(amount);
    }, [cookies]);

    return (
        <nav className={"desktop"}>
            <div className={"nav-wrapper max-w-l"}>
                <div className={"identity"}>
                    <NavLink to={"/"}>
                        <img src="/logo.svg" alt="logo"/>
                        <div className={"shop-name"}>Nerdy Relics</div>
                    </NavLink>
                </div>
                <NavbarSearch />
                <div className={"navigation-links"}>
                    <ul>
                        {/*<li>*/}
                        {/*    <NavLink to="/shop/cart">Categories</NavLink>*/}
                        {/*</li>*/}
                        <li className={"user-icon"}>
                            <NavLink to={"/account/login"}><FontAwesomeIcon icon={faUser} /></NavLink>
                            <div className={"user-dropdown"}>
                                { auth ?
                                    <ul>
                                        <p className={"text-gray-500 italic"}>Welcome {user ? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] : ""}!</p>
                                        <li><NavLink to="/account">Account</NavLink></li>
                                        {(user && user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] === "Admin") ? (
                                            <li><NavLink to="/admin/dashboard">Admin Panel</NavLink></li>
                                        ) : ""}
                                        <li><button onClick={() => {
                                            const cookies = new Cookies();
                                            dispatch(signOut());
                                            dispatch(clearUserData());
                                            cookies.remove("_jwt");
                                            toast.success('Logged out');
                                            navigate("/");
                                        }}>Logout</button></li>
                                    </ul>
                                :
                                    <ul>
                                        <li><NavLink to="/account/login">Login</NavLink></li>
                                        <li><NavLink to="/account/register">Register</NavLink></li>
                                    </ul>
                                }
                            </div>
                        </li>
                        <li>
                            <NavLink to={"/cart"}>
                                <FontAwesomeIcon icon={faCartShopping} />
                                {cartAmount > 0 ? <span className={"cart-badge"}>{cartAmount}</span> : ""}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}