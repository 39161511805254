import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "../Account.css"
import {useSelector} from "react-redux";
import axios from "axios";
import Cookies from "universal-cookie";
import {RootState} from "../../../state/store";
import ProductType from "../../../Types/ProductType";
import {numberFormatSummary} from "../../../scripts/NumberFormat";
import {faBagsShopping, faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface OrderType {
    id: string,
    orderNumber: string,
    orderProducts: OrderProductType[] | null,
    orderDate: Date,
    completed: boolean,
    completedDate: Date | null,
    mollieStatus: string | null,
    subTotal: number,
    vat: number,
    user: UserType
}

interface UserType {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    username: string
}

interface OrderProductType {
    product: ProductType,
    order: OrderType,
    amount: number
}

function OrdersAccount() {
    const user = useSelector((state: RootState) => state.userPersisted.user.value);
    const [changingAddress, setChangingAddress] = useState<boolean>();
    const [orders, setOrders] = useState<OrderType[]>([]);
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        getOrders();
    }, [navigate]);

    function getOrders() {
        axios({
            method: "get",
            url: "https://shopapi.deagarys.com/api/Orders/GetForUser",
            headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data) {
                    setOrders(response.data);
                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    function changeAddress() {
        setChangingAddress(true)
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"account-wrapper max-w-l"}>
                <div>
                    <ul>
                        <li><Link to={"/account"}><FontAwesomeIcon icon={faUser} /> - Account</Link></li>
                        <li><Link to={"/account/orders"}><FontAwesomeIcon icon={faBagsShopping} /> - Orders</Link></li>
                    </ul>
                </div>
                <div>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className={"ext-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
                        <tr>
                            <th className={"p-2"}>Order Number</th>
                            <th>Order Date</th>
                            <th>Total</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {orders.map((data, index) => (
                            <tr key={index} className={"odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"}>
                                <td className={"p-5"}>{data.orderNumber}</td>
                                <td className={"p-5"}>{new Date(data.orderDate).toLocaleDateString()}</td>
                                <td className={"p-5"}>{numberFormatSummary(data.subTotal)}</td>
                                <td className={"p-5"}><Link to={`/orders/${data.orderNumber}`} className={"button-action block w-full border-radius"}>View</Link></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default OrdersAccount;