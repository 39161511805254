import React, {useEffect, useState} from 'react';
import Sidebar from "../../../components/Navbar/Sidebar";
import "../Admin.css";
import Modal from 'react-modal';
import CategoriesDashboardOverview from "./CategoriesDashboardOverview";
import AddProductModal from "../Products/AddProductModal";
import AddCategoryModal from './AddCategoryModal';

type Inputs = {
    productName: string,
    prettyLink: string,
    price: number,
    stock: number,
    image: string,
    subCategoryId: OptionsType[]
};

interface OptionsType {
    value: string,
    label: string
}

interface SubCategoryIds {
    subCategoryId: string
}

function CategoriesDashboard() {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {

    }, [modalIsOpen]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper main-wrapper-admin">
            <Sidebar />
            <div className={"admin-dashboard-wrapper"}>
                <button onClick={openModal} className={"button-action"}>Add Category</button>
                <CategoriesDashboardOverview />
                <AddCategoryModal modalClose={closeModal} isOpen={modalIsOpen} handleClassName={"categories-modal"}/>
            </div>
        </div>
    );
}

export default CategoriesDashboard;