import Cookies from 'universal-cookie';
import {jwtDecode} from "jwt-decode";
import {CustomJwtPayload} from "../../../Routing";
import JWT from "../../../Types/Jwt";
import {toast} from "sonner";
import axios from "axios";

export async function Login(values: {}): Promise<string> {
    // const res = await fetch('https://shopapi.deagarys.com/authentication/login', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(values),
    // });
    let returnString = "";
    await axios.post('https://shopapi.deagarys.com/authentication/login', values).then((res) => {
        if (res.status != 200) {
            returnString = "Unknown error";
            return false;
        }

        const data = res.data;

        const cookies = new Cookies();
        // cookies.set("at", data.accessToken, {secure: true, path: "/"});
        // cookies.set("rt", data.refreshToken, {secure: true, path: "/"});

        let token: JWT = {
            email: jwtDecode<CustomJwtPayload>(data.accessToken)["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
            jwt: {
                accessToken: data.accessToken,
                refreshToken: data.refreshToken
            }
        }

        toast.success('Successfully logged in');
        cookies.set("_jwt", token, {secure: true, path: "/"});

        returnString = "true";
    }).catch((e) => {
        returnString = e.response.data;
    })

    return returnString;
}