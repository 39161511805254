import React, {useEffect, useState} from 'react';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faXmark} from "@fortawesome/pro-light-svg-icons";
import Modal, {Classes, Styles} from "react-modal";

const customStyles: Styles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        inset: '50% auto auto 50%',
        position: 'absolute',
        border: '1px solid rgb(204, 204, 204)',
        background: 'rgb(255, 255, 255)',
        overflow: 'auto',
        borderRadius: '4px',
        outline: 'none',
        padding: '20px',
        minWidth: '300px'
    },
};

function CustomModal(props: {
    modalClose(): void,
    isOpen: boolean; style?: Styles | undefined,
    className?: string | Classes | undefined,
    title: string,
    children: string | JSX.Element | JSX.Element[],
    ref?: string | undefined
}) {

    return (
        <Draggable>
        <Modal ariaHideApp={false} isOpen={props.isOpen} style={customStyles} contentLabel={"Add product"} className={props.className}>
            <div className={"modal-wrapper"}>
                    <div className={"modal-title"}>
                        {props.title}
                        <FontAwesomeIcon icon={faXmark} onClick={props.modalClose} />
                    </div>
                <div className="modal-content">
                    {props.children}
                </div>
            </div>
        </Modal>
        </Draggable>
    );
}

export default CustomModal;