import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import CategoryType from "../../Types/CategoryType";
import ProductType from "../../Types/ProductType";
import {ProductRegion} from "../../Types/ProductRegion";
import {numberFormat} from "../../scripts/NumberFormat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping} from "@fortawesome/pro-regular-svg-icons";
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import "./Category.css"
import SubCategoryType from "../../Types/SubCategoryType";
function SubCategory() {
    const {id} = useParams();
    const [category, setCategory] = useState<SubCategoryType | null>(null);
    const [products, setProducts] = useState<ProductType[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        axios.get("https://shopapi.deagarys.com/api/SubCategories/GetByPrettyLink",
            {params: {
                    prettyLink: id
                }})
            .then(res => {
                let category: SubCategoryType = res.data;
                setCategory(res.data);
                setProducts(category.products);
            }).catch(
                //(e) => navigate("/notfound")
        );
    }, [id, navigate]);

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"category-wrapper max-w-l"}>
                <div className={"category-products-wrapper mt-10"}>
                    {products.map((data, index) => (
                        <Link to={`/product/${data.prettyLink}`} key={index} className={"border-radius"}>
                            <div key={index} className={"product-div border-radius"}>
                                <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(data.imagePath)}`} alt={"category"} className={"product-image border-radius"} width={1000} height={1000} />
                                <p className={"product-title"}>{data.name}</p>
                                <p className={"product-region"}>{ProductRegion[data.region]}</p>
                                <p className={"product-price"}>€{numberFormat(data.price)}</p>
                                <button className={"border-radius button-action"}><FontAwesomeIcon icon={faEye} /> View item</button>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default SubCategory;