import {Route, Routes, useNavigate} from "react-router-dom";
import Home from "./pages/Home";
import LoginPage from "./pages/account/login/page";
import NotFound from "./pages/error/NotFound";
import NavbarMobile from "./components/Navbar/NavbarMobile";
import Footer from "./components/footer";
import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import RegisterPage from "./pages/account/register/page";
import {jwtDecode, JwtPayload} from "jwt-decode";
import NavbarDesktop from "./components/Navbar/NavbarDesktop";
import Product from "./pages/product/Product";
import Cart from "./pages/cart/Cart";
import Cookies from "universal-cookie";
import PrivateRoutes from "./components/PrivateRoutes";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "./state/store";
import {signIn, signOut} from "./state/user/loggedInSlice";
import {clearUserData, setUserData} from "./state/user/userSlice";
import ScrollToTop from "./components/ScrollToTop";
import AlreadyLoggedInRoutes from "./components/AlreadyLoggedInRoutes";
import JWT from "./Types/Jwt";
import { Toaster } from 'sonner';
import Orders from "./pages/orders/Orders";
import Dashboard from "./pages/admin/Dashboard";
import AdminRoutes from "./components/AdminRoutes";
import ProductsDashboard from "./pages/admin/Products/ProductsDashboard";
import CategoriesDashboard from "./pages/admin/Categories/CategoriesDashboard";
import Category from "./pages/categories/Category";
import SubCategoriesDashboard from "./pages/admin/SubCategories/SubCategoriesDashboard";
import Verify from "./pages/account/verify/Verify";
import Account from "./pages/account/Account";
import OrdersAccount from "./pages/account/Orders/Orders";
import SubCategory from "./pages/subCategories/Category";
import OrdersDashboard from "./pages/admin/Orders/OrdersDashboard";
import UsersDashboard from "./pages/admin/Users/UsersDashboard";

export interface CustomJwtPayload extends JwtPayload {
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": string;
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata": string;
    // Add other custom claims here
}

async function ValidateAt(jwt: JWT) {
    const res = await fetch('https://shopapi.deagarys.com/authentication/authenticate', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(jwt.jwt.accessToken),
    });

    let responseText = await res.text();

    if (res.status === 200 && responseText === "false") {
        const cookies = new Cookies();

        const res = await fetch('https://shopapi.deagarys.com/Authentication/refresh', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(jwt),
        });

        if (res.status === 200) {
            let response = JSON.parse(await res.text());
            let newJwt = {
                email: jwt.email,
                jwt: {
                    accessToken: response.accessToken,
                    refreshToken: response.refreshToken
                }
            };

            cookies.set("_jwt", newJwt, {secure: true, path: "/"});
            return "true";
        }
        return false;
    }
    return responseText;
}

function Routing() {
    const auth = useSelector((state: RootState) => state.persisted.auth.value);
    const userPersisted = useSelector((state: RootState) => state.userPersisted.user.value);
    const dispatch = useDispatch();
    const [cookies] = useCookies(['at'])
    const navigate = useNavigate();

    useEffect(() =>  {
        const cookies = new Cookies();
        if (cookies.get("_jwt") !== undefined){
            let valid = async () => {
                return await ValidateAt(cookies.get("_jwt"));
            }
            valid().then((result) => {
                if (result === "true") {
                    dispatch(signIn());
                    dispatch(setUserData(jwtDecode<CustomJwtPayload>(cookies.get("_jwt").jwt.accessToken)));
                } else {
                    dispatch(signOut());
                    dispatch(clearUserData());
                }
            }).catch();
        }
        else {
            dispatch(signOut());
            dispatch(clearUserData());
        }
    }, [navigate, cookies, auth])

    return (
        <>
        <ScrollToTop />
        <NavbarDesktop />
        <NavbarMobile />
            <Routes>
                <Route element={<PrivateRoutes auth={auth} />}>
                    <Route path={"/orders/:id"} element={<Orders />}/>
                    <Route path={"/account"} element={<Account />}/>
                    <Route path={"/account/orders"} element={<OrdersAccount />}/>
                </Route>
                <Route element={<AdminRoutes user={userPersisted} /> }>
                    <Route path={"/admin/dashboard"} element={<Dashboard />}/>
                    <Route path={"/admin/products"} element={<ProductsDashboard />}/>
                    <Route path={"/admin/categories"} element={<CategoriesDashboard />}/>
                    <Route path={"/admin/subcategories"} element={<SubCategoriesDashboard />}/>
                    <Route path={"/admin/orders"} element={<OrdersDashboard />}/>
                    <Route path={"/admin/users"} element={<UsersDashboard />}/>
                </Route>
                <Route path={"/"} element={<Home />} />
                <Route element={<AlreadyLoggedInRoutes auth={auth} />}>
                    <Route path={"/account/login"} element={<LoginPage />} />
                    <Route path={"/account/register"} element={<RegisterPage />}/>
                    <Route path={"/account/verify/:id"} element={<Verify />}/>
                </Route>
                <Route path={"/cart"} element={<Cart />}/>
                <Route path={"/product/:id"} element={<Product />}/>
                <Route path={"/category/:id"} element={<Category />}/>
                <Route path={"/subcategory/:id"} element={<SubCategory />}/>
                <Route path={"*"} element={<NotFound />}/>
            </Routes>
        <Toaster toastOptions={{style: {marginTop: "50px"}}} position={"top-center"} richColors={true} />
        <Footer />
        </>
    )
}

export default Routing;