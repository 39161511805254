import React, { ReactNode } from 'react'
import {Link} from "react-router-dom";

export default function Footer () {

    return (
        <footer>
            <img src="/logo.svg" alt=""/>
            {/*<Link to="/contact">Contact</Link>*/}
            {/*<Link to="/">About</Link>*/}
            {/*<Link to="/">Payment methods</Link>*/}
            <p>For contact:</p>
            <p>contact@nerdyrelics.com</p>
            <div className={"copyright"}>
                <p>© 2024 by Nerdy Relics</p>
            </div>
        </footer>
    )
}