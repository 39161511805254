import {Link, useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {Register} from "./register";

type Inputs = {
    firstName: string,
    lastName: string,
    username: string,
    email: string,
    password: string,
    passwordConfirm: string,
};

export default function RegisterPage() {
    const navigate = useNavigate();

    async function CallLogin(data: Inputs) {
        let success = await Register(data);
        if (success === "true") {
            navigate("/account/login", {replace: true});
        }

        return success;
    }

    const {register, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        let message = await CallLogin(data);
        setError("root", {message: message.toString()})
    }
    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper login-wrapper">
            <div className={"login-modal max-w-l border-radius"}>
                <h1>Register</h1>
                <form onSubmit={handleSubmit(onSubmit)} className={`form-control`}>
                    <div className={"input-login-control"}>
                        <input {...register("firstName", {
                            required: "First name is required",
                            minLength: {
                                value: 4,
                                message: "First name must be at least 3 characters long"
                            },
                        })} type="text" className={`border-radius firstname-input ${errors.firstName ? "border-red" : ""}`} placeholder={"First name"}/>
                        {errors.firstName && (<span className={"text-red-500"}>{errors.firstName.message}</span>)}
                    </div>
                    <div className={"input-login-control"}>
                        <input {...register("lastName", {
                            required: "Last name is required",
                            minLength: {
                                value: 4,
                                message: "Last name must be at least 3 characters long"
                            },
                        })} type="text" className={`border-radius lastname-input ${errors.lastName ? "border-red" : ""}`} placeholder={"Last name"}/>
                        {errors.lastName && (<span className={"text-red-500"}>{errors.lastName.message}</span>)}
                    </div>
                    <div className={"input-login-control"}>
                        <input {...register("username", {
                            required: "Username is required",
                            minLength: {
                                value: 4,
                                message: "Username must be at least 4 characters long"
                            },
                        })} type="text" className={`border-radius username-input ${errors.username ? "border-red" : ""}`} placeholder={"Username"}/>
                        {errors.username && (<span className={"text-red-500"}>{errors.username.message}</span>)}
                    </div>
                    <div className={"input-login-control"}>
                        <input {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: "Email must be valid"
                            },
                        })} type="text" className={`border-radius email-input ${errors.email ? "border-red" : ""}`} placeholder={"Email"}/>
                        {errors.email && (<span className={"text-red-500"}>{errors.email.message}</span>)}
                    </div>
                    <div className={"input-login-control"}>
                        <input {...register("password", {
                            required: "Password is required",
                            minLength: {
                                value: 8,
                                message: "Password must be at least 4 characters long"
                            },
                            pattern: {
                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$.\-_#!%*?&])[A-Za-z\d@$.\-_#!%*?&]{8,}$/,
                                message: "Password must contain atleast: 1 letter, 1 number and 1 symbol."
                            }
                        })} type="password" className={`border-radius password-input ${errors.password ? "border-red" : ""}`} placeholder={"Password"}/>
                        {errors.password && (<span className={"text-red-500"}>{errors.password.message}</span>)}
                    </div>
                    <div className={"input-login-control"}>
                        <input {...register("passwordConfirm", {
                            required: "Passwords confirmation is required",
                            validate: (value, formValues) => {
                                if (value === formValues.password)
                                    return true

                                return "Passwords must match";
                            }
                        })} type="password" className={`border-radius password-input ${errors.passwordConfirm ? "border-red" : ""}`} placeholder={"Confirm password"}/>
                        {errors.passwordConfirm && (<span className={"text-red-500"}>{errors.passwordConfirm.message}</span>)}
                    </div>
                    <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Registering..." : "Register"}</button>
                    {errors.root && (<span className={"text-red-500"}>{errors.root.message}</span>)}
                </form>
                <p>Already have an account? <Link to="/account/login">Log in here</Link></p>
                <div className={"separator-text"}><span></span><p>or</p><span></span></div>
                <Link to="/account/resetpassword">Forgot password</Link>
            </div>
        </div>
    );
}
