import React from "react";

function NotFound() {
    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"hero-image"}>
                <div className={"hero-text"}>
                    <h1>404</h1>
                    <h1>Page not found</h1>
                </div>
            </div>
        </div>
    );
}

export default NotFound;