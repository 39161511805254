import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import "./Sidebar.css";
import {
    faBoxesStacked,
    faLayerGroup as catReg,
    faBagsShopping,
    faCardsBlank,
    faUsers
} from "@fortawesome/pro-light-svg-icons";
import {faLayerGroup} from "@fortawesome/pro-solid-svg-icons";

export default function Sidebar () {


    return (
        <aside>
            <Link to={"/admin/products"}><FontAwesomeIcon icon={faCardsBlank} />Products</Link>
            <Link to={"/admin/categories"}><FontAwesomeIcon icon={catReg} />Categories</Link>
            <Link to={"/admin/subcategories"}><FontAwesomeIcon icon={catReg} />Sub Categories</Link>
            <Link to={"/admin/orders"}><FontAwesomeIcon icon={faBagsShopping} />Orders</Link>
            <Link to={"/admin/users"}><FontAwesomeIcon icon={faUsers} />Users</Link>
        </aside>
    )
}