import React, {useEffect, useState} from "react";
import {faChevronRight, faMagnifyingGlass} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useOutsideClick} from "outsideclick-react";
import {Link} from "react-router-dom";

export default function NavbarSearch () {
    const [search, setSearch] = useState("");
    const [searchData, setSearchData] = useState([]);

    const [searchBarDropdownClass, setSearchBarDropdownClass] = useState("hidden");

    useEffect(() =>  {
        fetch(`https://shopapi.deagarys.com/search?search=${search}`)
            .then(response => response.json())
            .then(json => {
                setSearchData(json);
            })
            .catch();
    },[search])

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value)
    }

    const ref = useOutsideClick(() => setSearchBarDropdownClass("hidden"));

    return (
        <div className={"search-bar"} ref={ref}>
            <input type="text" className={"border-radius"} placeholder={"Search for category or product..."} onChange={handleSearch} onFocus={() => setSearchBarDropdownClass("flex")} />
            <button>go</button>
            <div className={`search-bar-dropdown ${searchBarDropdownClass}`}>
                <ul>
                    {searchData.map((searchItem, index) => (
                        <li className={"search-bar-dropdown-item cursor-pointer"} key={index}>
                            <Link to={`${searchItem["url"]}`} onClick={() => setSearchBarDropdownClass("hidden")}><span>{searchItem["name"]}</span><span>{searchItem["type"]}</span><FontAwesomeIcon icon={faChevronRight} /></Link>
                        </li>
                    ))}
                    {searchData.length === 0 ? (
                        <li className={"search-bar-dropdown-item no-result"}>
                            <span>No results found</span><span></span><FontAwesomeIcon icon={faMagnifyingGlass} />
                        </li>
                    ): ""}
                </ul>
            </div>
        </div>
    )
}