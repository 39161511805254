import React, {useEffect, useState} from 'react';
import Sidebar from "../../../components/Navbar/Sidebar";
import "../Admin.css";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import axios from "axios";
import Select from 'react-select'
import SubCategoryType from "../../../Types/SubCategoryType";
import Cookies from "universal-cookie";
import ProductsDashboardOverview from "./ProductsDashboardComponent";
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faXmark} from "@fortawesome/pro-light-svg-icons";
import Modal, {Classes, Styles} from "react-modal";
import CustomModal from "../../../components/CustomModal";

type Inputs = {
    productName: string,
    prettyLink: string,
    description: string,
    price: number,
    stock: number,
    region: OptionsType,
    type: OptionsType,
    image: string,
    subCategoryId: OptionsType[]
};

interface OptionsType {
    value: string,
    label: string
}

interface SubCategoryIds {
    subCategoryId: string
}

const optionsRegion = [
    { value: '0', label: 'Japanese' },
    { value: '1', label: 'English' }
]

const optionsType = [
    { value: '0', label: 'Booster' },
    { value: '1', label: 'Single' }
]

function ProductsDashboard(props: {
    modalClose(): void,
    isOpen: boolean; style?: Styles | undefined,
    className?: string | Classes | undefined,
    handleClassName: string,
}) {
    const {register, control, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<Inputs>();
    const [options, setOptions] = useState<OptionsType[]>([]);
    const [productName, setProductName] = useState("");

    useEffect(() => {
        axios.get("https://shopapi.deagarys.com/api/SubCategories")
            .then(res => {
                let options: OptionsType[] = [];
                res.data.forEach((item: SubCategoryType) => {
                    options.push({value: item.id, label: item.name});
                })
                setOptions(options);
            })
    }, [props.isOpen]);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        var formData = new FormData();
        formData.append("Name", data.productName);
        formData.append("Price", data.price.toString().replace(",", "."));
        formData.append("Stock", data.stock.toString());
        formData.append("PrettyLink", data.prettyLink);
        formData.append("Description", data.description);
        formData.append("Type", data.type.value);
        formData.append("Region", data.region.value);
        // formData.append("SubCategoryIds", JSON.stringify(subCategories));
        formData.append("Image", data.image[0]);

        data.subCategoryId.forEach((item, index) => {
            formData.append(`SubCategoryIds[${index}]`, item.value);
        });

        const cookies = new Cookies();
        await axios({
            method: "post",
            url: "https://shopapi.deagarys.com/api/Products",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                props.modalClose();
            })
            .catch(function (response) {
                //handle error
            });
    }

    function syncPrettyLink(e: React.ChangeEvent<HTMLInputElement>) {
        setProductName(e.currentTarget.value);
    }

    return (
        <CustomModal isOpen={props.isOpen} title={"Add product"} modalClose={props.modalClose}>
            <form onSubmit={handleSubmit(onSubmit)} className={`form-control`}>
                <div className={""}>
                    <input {...register("productName", {
                        required: "Name is required"
                    })} className={`border-radius ${errors.productName ? "border-red" : ""}`} onChange={syncPrettyLink} placeholder={"Product name"}/>
                </div>
                {errors.productName && (<span className={"text-red-500"}>{errors.productName.message}</span>)}
                <div className={""}>
                    <input {...register("prettyLink", {
                        required: "prettyLink is required"
                    })} className={`border-radius ${errors.productName ? "border-red" : ""}`} defaultValue={productName.toLowerCase().replace(/ /g, "-")} placeholder={"prettyLink name"}/>
                </div>
                {errors.prettyLink && (<span className={"text-red-500"}>{errors.prettyLink.message}</span>)}
                <div className={""}>
                    <input {...register("description", {
                        required: "description is required"
                    })} className={`border-radius ${errors.description ? "border-red" : ""}`} placeholder={"description"}/>
                </div>
                {errors.description && (<span className={"text-red-500"}>{errors.description.message}</span>)}
                <div className={""}>
                    <input {...register("price", {
                        required: "Price is required",
                        pattern: {
                            value: /^\d+(.\d{1,2})?$/,
                            message: "Price must be valid"
                        },
                    })} type={"text"} className={`border-radius ${errors.price ? "border-red" : ""}`} placeholder={"Product price"}/>
                </div>
                {errors.price && (<span className={"text-red-500"}>{errors.price.message}</span>)}
                <div className={""}>
                    <input {...register("stock", {
                        required: "Stock is required"
                    })} className={`border-radius ${errors.stock ? "border-red" : ""}`} placeholder={"Product stock"}/>
                </div>
                {errors.stock && (<span className={"text-red-500"}>{errors.stock.message}</span>)}
                <div>
                    <Controller
                        control={control}
                        name={"region"}
                        render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                            <Select required={true} onChange={onChange} name={name} options={optionsRegion} isMulti={false} />
                        )}
                    />
                </div>
                <div>
                    <Controller
                        control={control}
                        name={"type"}
                        render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                            <Select required={true} onChange={onChange} name={name} options={optionsType} isMulti={false} />
                        )}
                    />
                </div>
                <div className={""}>
                    <input {...register("image", {
                        required: "Image is required"
                    })} type={"file"} className={`border-radius ${errors.image ? "border-red" : ""}`} placeholder={"Product image"}/>
                </div>
                {errors.image && (<span className={"text-red-500"}>{errors.image.message}</span>)}
                <div className={""}>
                    <Controller
                        control={control}
                        name={"subCategoryId"}
                        render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                            <Select required={true} onChange={onChange} name={name} options={options} isMulti={true} />
                        )}
                    />
                </div>
                {errors.subCategoryId && (<span className={"text-red-500"}>{errors.subCategoryId.message}</span>)}
                <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Adding..." : "Add Product"}</button>
            </form>
        </CustomModal>
    );
}

export default ProductsDashboard;