import React, {FocusEventHandler, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../state/store";
import axios from "axios";
import Cookies from "universal-cookie";
import "./Address.css"
import {SubmitHandler, useForm} from "react-hook-form";

export interface AddressType {
    streetNumber: string,
    streetName: string,
    city: string,
    province?: string | null,
    country: string,
    zipCode: string
}

interface ApiData {
    street: string,
    number: number,
    numberAddition: string | null,
    city: string,
    postalcode: string,
    Country: ApiCountry
}

interface ApiCountry {
    code: string,
    name: string,
    nameInt: string
}

interface AddressComponentsType {
    long_name: string,
    short_name: string,
    types: []
}

type Inputs = {
    postalCode: string,
    number: number,
    addition: string,
    streetName: string,
    city: string
};

function AddressComponent(props: {
    mode: string,
    savedCallback: React.Dispatch<React.SetStateAction<boolean>>,
    resetSavedCallback?: React.Dispatch<React.SetStateAction<boolean>>,
    className: string | undefined
}) {
    const user = useSelector((state: RootState) => state.userPersisted.user.value);
    const [address, setAddress] = useState();
    const [apiAddress, setApiAddress] = useState<ApiData>();
    const [addressButtonDisabled, setAddressButtonDisabled] = useState(true);
    const navigate = useNavigate();
    const cookies = new Cookies();


    const {register, formState, setValue, getValues, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<Inputs>({
        mode: 'onBlur',
    });

    useEffect(() => {
        getDeliveryAddress();
    }, [apiAddress]);

    function getDeliveryAddress() {
        axios({
            method: "get",
            url: "https://shopapi.deagarys.com/api/DeliveryAddress",
            headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data) {
                    setAddress(response.data);
                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    async function findAddress(data: Inputs) {
        axios.get(`https://api.apicheck.nl/lookup/v1/address/nl?postalcode=${data.postalCode}&number=${data.number}&numberAddition=${data.addition}`, { headers: {"X-API-KEY" : `WhXfCIR5s1UQ4L8dxFjrScmo6DqeYAuK`} })
            .then(res => {
                let apiData: ApiData = res.data.data
                setValue("streetName", apiData.street);
                setValue("city", apiData.city);
                setApiAddress(apiData);
            }).catch((e) => {
                setError("root", {message: "Address not found."});
        });
    }
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        await findAddress(data);
        await saveAddress();
    }

    const onBlur = async () => {
        const {isValid} = formState;
        const formValues = getValues();

        if (isValid) {
            await findAddress(formValues);
        }
    }

    async function saveAddress() {
        let data = apiAddress;
        if (data === undefined)
            return;

        const savedAddress: AddressType = {
            streetNumber: data.numberAddition !== null ? `${data.number} ${data.numberAddition}` : data.number.toString(),
            streetName: data.street,
            city: data.city,
            province: null,
            country: data.Country.name,
            zipCode: data.postalcode
        }

        if (props.mode === "new") {
            await axios({
                method: "post",
                url: "https://shopapi.deagarys.com/api/DeliveryAddress",
                data: savedAddress,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${cookies.get("_jwt").jwt.accessToken}`
                },
            })
                .then(function (response) {
                    props.savedCallback(true);
                })
                .catch(function (response) {
                    //handle error
                });
        } else if (props.mode === "update") {
            await axios({
                method: "put",
                url: "https://shopapi.deagarys.com/api/DeliveryAddress",
                data: savedAddress,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${cookies.get("_jwt").jwt.accessToken}`
                },
            })
                .then(function (response) {
                    props.savedCallback(true);
                })
                .catch(function (response) {
                    //handle error
                });
        }

        if (props.resetSavedCallback !== undefined)
            props.resetSavedCallback(false);
    }

    return (
        <div className={`p-2 bg-white ${props.className}`}>
            {errors.root && (<span className={"text-red-500"}>{errors.root.message}</span>)}
            <form onSubmit={handleSubmit(onSubmit)} className={`address-form`}>
                <div className={""}>
                    <label htmlFor="number">Postcode</label>
                    <input {...register("postalCode", {
                        required: "Postal code is required"
                    })} type="text" className={`border-radius ${errors.postalCode ? "border-red" : ""}`} placeholder={"1010 AD"} onBlur={onBlur}/>
                    {errors.postalCode && (<span className={"text-red-500"}>{errors.postalCode.message}</span>)}
                </div>
                <div className={""}>
                    <label htmlFor="number">House number</label>
                    <input {...register("number", {
                        required: "House number is required"
                    })} type="text" className={`border-radius ${errors.number ? "border-red" : ""}`} placeholder={"12"} onBlur={onBlur}/>
                    {errors.number && (<span className={"text-red-500"}>{errors.number.message}</span>)}
                </div>
                <div className={""}>
                    <label htmlFor="">Suffix</label>
                    <input {...register("addition", {})} type="text" className={`border-radius ${errors.addition ? "border-red" : ""}`} placeholder={"A"} onBlur={onBlur}/>
                </div>
                <div className={"street-input"}>
                    <input {...register("streetName", {})} type="text" className={`border-radius ${errors.streetName ? "border-red" : ""}`} placeholder={"Street Name"} disabled={true}/>
                </div>
                <div className={"city-input"}>
                    <input {...register("city", {})} type="text" className={`border-radius ${errors.city ? "border-red" : ""}`} placeholder={"City"} disabled={true}/>
                </div>
                {errors.addition && (<span className={"text-red-500"}>{errors.addition.message}</span>)}
                <div className={"form-button-wrapper"}>
                    <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Saving..." : "Save address"}</button>
                </div>
            </form>
        </div>
    );
}

export default AddressComponent;