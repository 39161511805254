/// <reference types="redux-persist" />
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import loggedInReducer from "./user/loggedInSlice";
import userReducer from "./user/userSlice";
import {persistReducer, persistStore} from "redux-persist";

const persistConfig = {
    key: "root",
    storage
};

const authReducer = combineReducers({
    auth: persistReducer(persistConfig, loggedInReducer)
})

const userPersisted = combineReducers({
    user: persistReducer(persistConfig, userReducer)
})

export const store = configureStore({
    reducer: {
        userPersisted: userPersisted,
        persisted: authReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: false
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;