import React, {useEffect, useState} from "react";
import axios from "axios";
import Cookies from "universal-cookie";
import {AddressType} from "../../../components/Address/Address";

interface UserType {
    id: string,
    email: string,
    firstName: string,
    lastName: string,
    username: string,
    lastLoginDate: Date,
    verifiedAt: Date,
    deliveryAddress: AddressType
}

function UsersDashboardOverview() {
    const [users, setUsers] = useState<UserType[]>()
    const cookies = new Cookies();

    useEffect(() => {
        getUsers();
    }, []);

    function getUsers() {
        axios({
            method: "get",
            url: "https://shopapi.deagarys.com/api/Users/",
            headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data) {
                    setUsers(response.data);
                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    return (
        <div className={"categories-wrapper w-full"}>
            <div className={"categories-table"}>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className={"ext-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Last login date</th>
                        <th>Verified at</th>
                        <th>Address</th>
                    </tr>
                    </thead>
                    <tbody>
                    {users?.map((data, index) => (
                        <tr key={index} className={"odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"}>
                            <td className={""}>{data.username}</td>
                            <td className={""}>{data.email}</td>
                            <td className={""}>{new Date(data.lastLoginDate).toLocaleDateString() === "01/01/1970" ? "Never" : new Date(data.lastLoginDate).toLocaleString()}</td>
                            <td className={""}>{new Date(data.verifiedAt).toLocaleDateString() === "01/01/1970" ? "Never" : new Date(data.verifiedAt).toLocaleDateString()}</td>
                            <td className={""}>{data.deliveryAddress === null ? "Not set" : `${data.deliveryAddress.streetName} ${data.deliveryAddress.streetNumber} ${data.deliveryAddress.city} ${data.deliveryAddress.zipCode}`}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default UsersDashboardOverview;