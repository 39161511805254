"use client"
import {Link, useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {Login} from "./login";
import {useDispatch,} from "react-redux";
import {signIn, signOut} from "../../../state/user/loggedInSlice";
import {useEffect, useState} from "react";

type Inputs = {
    email: string,
    password: string
};

export default function LoginPage() {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {

    }, [errorMessage])

    async function CallLogin(data: Inputs) {
        let success: boolean | string = await Login(data);
        if (success === "true") {
            dispatch(signIn());
            navigate("/", {replace: true});
            return;
        }


        setError("root", {message: success.toString()})
        dispatch(signOut());
    }

    const {register, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<Inputs>();
    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        await CallLogin(data);
        //setError("root", {message: errorMessage})
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper login-wrapper">
            <div className={"login-modal max-w-l border-radius"}>
                <h1>Login</h1>
                <form onSubmit={handleSubmit(onSubmit)} className={`form-control`}>
                    <div className={"input-login-control"}>
                        <input {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
                                message: "Email must be valid"
                            },
                        })} type="text" className={`border-radius email-input ${errors.email ? "border-red" : ""}`} placeholder={"john@doe.com"}/>
                    </div>
                    {errors.email && (<span className={"text-red-500"}>{errors.email.message}</span>)}
                    <div className={"input-login-control"}>
                        <input {...register("password", {
                            required: "Password is required"
                        })} type="password" className={`border-radius password-input ${errors.password ? "border-red" : ""}`} placeholder={"Password"}/>
                    </div>
                    {errors.password && (<span className={"text-red-500"}>{errors.password.message}</span>)}
                    <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Logging in..." : "Login"}</button>
                    {errors.root && (<span className={"text-red-500"}>{errors.root.message}</span>)}
                </form>
                <Link to="/account/resetpassword">Forgot password</Link>
                <div className={"separator-text"}><span></span><p>or</p><span></span></div>
                <p>Need an account? <Link to="/account/register">Register here</Link></p>
            </div>
        </div>
    );
}
