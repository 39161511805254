import React, {useEffect, useState} from 'react';
import "../Admin.css";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import Modal, {Classes, Styles} from "react-modal";
import CustomModal from "../../../components/CustomModal";
import axios from "axios";
import Cookies from "universal-cookie";
import Select from "react-select";
import SubCategoryType from "../../../Types/SubCategoryType";

type Inputs = {
    productName: string,
    prettyLink: string,
    category: OptionsType,
    image: string,
};

interface OptionsType {
    value: string,
    label: string
}

function AddSubCategoryModal(props: {
    modalClose(): void,
    isOpen: boolean; style?: Styles | undefined,
    className?: string | Classes | undefined,
    handleClassName: string,
}) {
    const {register, control, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<Inputs>();
    const [options, setOptions] = useState<OptionsType[]>([]);

    useEffect(() => {
        axios.get("https://shopapi.deagarys.com/api/Categories")
            .then(res => {
                let options: OptionsType[] = [];
                res.data.forEach((item: SubCategoryType) => {
                    options.push({value: item.id, label: item.name});
                })
                setOptions(options);
            })
    }, [props.isOpen]);

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        var formData = new FormData();
        formData.append("Name", data.productName);
        formData.append("PrettyLink", data.prettyLink);
        formData.append("CategoryId", data.category.value);
        formData.append("Image", data.image[0]);

        const cookies = new Cookies();
        await axios({
            method: "post",
            url: "https://shopapi.deagarys.com/api/SubCategories",
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                props.modalClose();
            })
            .catch(function (response) {
                //handle error
            });
    }

    return (
        <CustomModal isOpen={props.isOpen} title={"Add sub category"} modalClose={props.modalClose}>
            <form onSubmit={handleSubmit(onSubmit)} className={`form-control`}>
                <div className={""}>
                    <input {...register("productName", {
                        required: "Name is required"
                    })} className={`border-radius ${errors.productName ? "border-red" : ""}`} placeholder={"Product name"}/>
                </div>
                {errors.productName && (<span className={"text-red-500"}>{errors.productName.message}</span>)}
                <div className={""}>
                    <input {...register("prettyLink", {
                        required: "prettyLink is required"
                    })} className={`border-radius ${errors.productName ? "border-red" : ""}`} placeholder={"prettyLink name"}/>
                </div>
                {errors.prettyLink && (<span className={"text-red-500"}>{errors.prettyLink.message}</span>)}
                <div className={""}>
                    <input {...register("image")} type={"file"} className={`border-radius ${errors.image ? "border-red" : ""}`} placeholder={"Product image"}/>
                </div>
                {errors.image && (<span className={"text-red-500"}>{errors.image.message}</span>)}
                <div className={""}>
                    <Controller
                        control={control}
                        name={"category"}
                        render={({ field: { onChange, onBlur, value, name,  ref }}) => (
                            <Select required={true} onChange={onChange} name={name} options={options} isMulti={false} />
                        )}
                    />
                </div>
                {errors.category && (<span className={"text-red-500"}>{errors.category.message}</span>)}
                <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Adding..." : "Add Category"}</button>
            </form>
        </CustomModal>
    );
}

export default AddSubCategoryModal;