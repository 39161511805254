import {BrowserRouter, Route, Routes, useNavigate} from "react-router-dom";
import Routing from "../Routing";
import React, {useEffect, useState} from "react";

function App() {
    // useEffect(() => {
    //     const script = document.createElement('script');
    //
    //     script.src = "https://kit.fontawesome.com/fac627af52.js";
    //     script.crossOrigin = "anonymous"
    //     script.async = true;
    //
    //     document.body.appendChild(script);
    // }, []);
    return (
        <main>
            <BrowserRouter>
                <Routing />
            </BrowserRouter>
        </main>
    )
}

export default App;