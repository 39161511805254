import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import CategoryType from "../../Types/CategoryType";
import ProductType from "../../Types/ProductType";
import {ProductRegion} from "../../Types/ProductRegion";
import {numberFormat} from "../../scripts/NumberFormat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faCartShopping} from "@fortawesome/pro-regular-svg-icons";
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import "./Category.css"
function Category() {
    const {id} = useParams();
    const [category, setCategory] = useState<CategoryType | null>(null);
    const [products, setProducts] = useState<ProductType[]>([])
    const [pageNumber, setPageNumber] = useState(1);
    const [maxPage, setMaxPage] = useState(1);
    const [pageNumbers, setPageNumbers] = useState<number[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get("https://shopapi.deagarys.com/api/Categories/GetByPrettyLink",
            {params: {
                    prettyLink: id
                }})
            .then(res => {
                let category: CategoryType = res.data;
                setCategory(res.data);
            }).catch((e) => navigate("/notfound"));

        axios.get(`https://shopapi.deagarys.com/api/products/GetByPagination?PageNumber=${pageNumber}&MaxItemsPerPage=${12}`)
            .then(res => {
                setProducts(res.data.products);
                setMaxPage(res.data.maxPages);
                createPagesButtons(res.data.maxPages);
            }).catch();
    }, [id, navigate, pageNumber]);

    function createPagesButtons(maxPageNumber: number) {
        let pages: number[] = [];
        for (let i = 1; i <= maxPageNumber; i++) {
            pages.push(i);
        }
        setPageNumbers(pages);
    }

    function previousPage() {
        if (pageNumber === 1)
            return;

        setPageNumber(pageNumber - 1);
    }

    function nextPage() {
        if (pageNumber === maxPage)
            return;

        setPageNumber(pageNumber + 1);
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"category-wrapper max-w-l"}>
                <div className={"category-categories-wrapper"}>
                    {category?.subCategories.map((data, index) => (
                        <div key={index}>
                            <Link className={"border-radius"} to={`/subcategory/${data.prettyLink}`} key={index}>
                                <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(data.imagePath)}`} className={"border-radius"} alt=""/>
                            </Link>
                            <p>{data.name}</p>
                        </div>
                    ))}
                </div>

                <div className={"category-products-wrapper"}>
                    {products.map((data, index) => (
                        <Link to={`/product/${data.prettyLink}`} key={index} className={"border-radius"}>
                            <div key={index} className={"product-div border-radius"}>
                                <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(data.imagePath)}`} alt={"category"} className={"product-image border-radius"} width={1000} height={1000} />
                                <p className={"product-title"}>{data.name}</p>
                                <p className={"product-region"}>{ProductRegion[data.region]}</p>
                                <p className={"product-price"}>€{numberFormat(data.price)}</p>
                                <button className={"border-radius button-action"}><FontAwesomeIcon icon={faEye} /> View item</button>
                            </div>
                        </Link>
                    ))}
                </div>
                <div className="page-numbers">
                    <div className={"previous-page-category"} onClick={previousPage}><FontAwesomeIcon icon={faArrowLeft} style={{color: "#FFFFFF",}} /></div>
                    {pageNumbers.map((data, index) => (
                        <button className={pageNumber === data? "active" : ""} onClick={() => setPageNumber(data)}>{data}</button>
                    ))}
                    <div className={"next-page-category"} onClick={nextPage}><FontAwesomeIcon icon={faArrowRight} style={{color: "#FFFFFF",}} /></div>
                </div>
            </div>
        </div>
    );
}

export default Category;