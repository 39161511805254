import React, {useEffect, useState} from 'react';
import Cookies from "universal-cookie";
import CartCookieType from "../../Types/CartCookieType";
import axios from "axios";
import "./cart.css";
import {numberFormatSummary} from "../../scripts/NumberFormat";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping } from '@fortawesome/pro-duotone-svg-icons';
import SubCategoryType from "../../Types/SubCategoryType";
import {faTrash} from "@fortawesome/pro-light-svg-icons";
import {useCookies} from "react-cookie";
import {faCcVisa, faIdeal, faPaypal} from "@fortawesome/free-brands-svg-icons";
import {useSelector} from "react-redux";
import {RootState} from "../../state/store";
import {Link, useNavigate} from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import {GoogleAddressParser} from "../../scripts/GoogleAddressParser";
import AddressComponent from "../../components/Address/Address";
import {toast} from "sonner";

interface CartItemType extends CartCookieType {
    name: string,
    price : number,
    stock: number,
    subCategories: SubCategoryType[],
    imagePath: string
}

interface ProductOrder {
    productId: string,
    amount: number
}

interface Order {
    userId: string,
    vat: number,
    products: ProductOrder[],
    deliveryAddress: Address,
    coupon: CouponType | undefined
}

export interface Address {
    id: number | undefined,
    streetNumber: string,
    streetName: string,
    city: string,
    province: string,
    country: string,
    zipCode: string
}

export interface CouponType {
    activatesDate: Date,
    expiresDate: Date,
    code: string,
    percentage: number,
    id: string
}

async function GetCart(cart: CartItemType[]) {
    let cartItemsArr: CartItemType[] = [];
    for (const item of cart) {
        await axios.get(`https://shopapi.deagarys.com/api/Products/${item.id}`)
            .then(res => {
                let cartItem: CartItemType = {
                    id: res.data.id,
                    name: res.data.name,
                    price: res.data.price,
                    amount: item.amount > res.data.stock ? res.data.stock : item.amount,
                    stock: res.data.stock,
                    subCategories: res.data.subCategories,
                    imagePath: res.data.imagePath
                }
                cartItemsArr.push(cartItem);
            }).catch((e) => e);
    }

    return cartItemsArr;
}

function Home() {
    const [cartItems, setCartItems] = useState<CartItemType[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [cartAmount, setCartAmount] = useState<number>(0);
    const [cookies] = useCookies(['cartCookie']);
    const [shippingCost, setShippingCost] = useState<number>(5);
    const user = useSelector((state: RootState) => state.userPersisted.user.value);
    const navigate = useNavigate();
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [savedAddress, setSavedAddress] = useState(false);
    const [changingAddress, setChangingAddress] = useState(false);
    const [addressButtonDisabled, setAddressButtonDisabled] = useState(true);
    const [address, setAddress] = useState();
    const [code, setCode] = useState("");
    const [coupon, setCoupon] = useState<CouponType>();
    const [addressDefined, setAddressDefined] = useState(false);
    const [error, setError] = useState("");
    const auth = useSelector((state: RootState) => state.persisted.auth.value);

    useEffect(() => {
        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie");
        if (cartCookie !== undefined && cartCookie !== "") {
            let cartCookieData: CartItemType[] = JSON.parse(decodeURI(cartCookie));

            let getCartHandler = async () => {
                return await GetCart(cartCookieData);
            }

            getCartHandler().then((result) => {
                setCartItems(result);

                let total = 0;
                let amount = 0;
                result.forEach((item) => {
                    total += (item.price * item.amount);
                    amount += item.amount * 1;
                })

                if (coupon && coupon.code !== "Not found")
                    setTotal((total / 100) * (100 - coupon.percentage));
                else
                    setTotal(total);

                setCartAmount(amount);
            });
        }

        if (auth) {
            axios({
                method: "get",
                url: "https://shopapi.deagarys.com/api/DeliveryAddress",
                headers: {"Authorization": `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
            })
                .then(function (response) {
                    if (response.data) {
                        setAddressDefined(true);
                        setButtonDisabled(false);
                        setAddress(response.data);
                    }
                })
                .catch(function (response) {
                    //handle error
                });
        }
    }, [cookies, buttonDisabled, navigate, savedAddress, changingAddress, coupon]);

    async function handleSelected(e: React.ChangeEvent<HTMLSelectElement>) {
        let newValue = e.target.value;
        let id = e.currentTarget.dataset["id"];

        let res = await axios({
            url: `https://shopapi.deagarys.com/api/Products/${id}`,
            method: "get"
        });

        if (res.data.stock < parseInt(newValue))
            return;

        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie");

        if (cartCookie === undefined || cartCookie === "")
            return;

        let cartCookieObject = JSON.parse(decodeURI(cartCookie));
        let productExistsInCart = cartCookieObject.find((element: CartCookieType) => {
            return element.id === id
        });

        productExistsInCart.amount = newValue;
        cookies.set("cartCookie", encodeURI(JSON.stringify(cartCookieObject)), {secure: true, path: "/"});
    }

    function handleTrashClick(e: React.MouseEvent<HTMLOrSVGElement>) {
        let id = e.currentTarget.dataset["id"];

        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie");

        if (cartCookie === undefined || cartCookie === "")
            return;

        let cartCookieObject: CartCookieType[] = JSON.parse(decodeURI(cartCookie));
        if (cartCookieObject === undefined)
            return;

        let productExistsInCart = cartCookieObject.find((element: CartCookieType) => {
            return element.id === id
        });

        if (productExistsInCart === undefined)
            return;

        productExistsInCart.amount--;
        if (productExistsInCart.amount === 0)
            cartCookieObject.splice(cartCookieObject.indexOf(productExistsInCart), 1);

        cookies.set("cartCookie", encodeURI(JSON.stringify(cartCookieObject)), {secure: true, path: "/"});
    }

    function handleRemoveAll(e: React.MouseEvent<HTMLButtonElement>) {
        const cookies = new Cookies();
        let cartCookie = cookies.get("cartCookie")
        let cartCookieObject: CartCookieType[] = JSON.parse(decodeURI(cartCookie));

        while (cartCookieObject.length > 0) {
            cartCookieObject.pop();
        }

        cookies.set("cartCookie", encodeURI(JSON.stringify(cartCookieObject)), {secure: true, path: "/"});
    }

    const getOptions = (item: CartItemType) =>{
        let options = [];
        for (let i = 1; i <= item.stock; i++)
        {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    }

    async function sendOrder(e: React.MouseEvent<HTMLButtonElement>) {
        setButtonDisabled(true);
        let products: ProductOrder[] = [];
        cartItems.forEach((item, index) => {
            let product: ProductOrder = {
                productId: item.id ? item.id : "",
                amount: item.amount * 1
            }

            products.push(product);
        });

        if (address === undefined)
            return;

        let order: Order = {
            userId: user ? user["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"] : "",
            vat: 21,
            products: products,
            deliveryAddress: address,
            coupon: coupon
        }

        let returnUrl = await axios.post("https://shopapi.deagarys.com/api/orders", order);

        window.open(returnUrl.data, "_self");
    }

    function addCoupon(e: React.MouseEvent<HTMLButtonElement>) {
        if (code === "" || code === undefined || code === null)
            return;

        axios.get(`https://shopapi.deagarys.com/api/Coupons/${code}`)
            .then((res) => {
                setCoupon(res.data);
                if (res.data.code === "Not found")
                    toast.error("Invalid coupon code");
                else
                    toast.success("Coupon added!")
            })
            .catch(() => {
                toast.error("Something went wrong")
            });
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"cart-wrapper max-w-l"}>
                <div className={"articles"}>
                    <h1><FontAwesomeIcon icon={faCartShopping} />Shopping cart</h1>
                    {cartItems.length === 0 ? (
                        <div>Empty cart</div>
                    ) : ""}
                    {cartItems.map((item, index) => (
                        <div key={index} className={"article"}>
                            <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(item.imagePath)}`} alt=""/>
                            <p className={"category"}>{item.subCategories[0].category.name}</p>
                            <p className={"article-name"}>{item.name}</p>
                            <select name="amount" id="article-amount" value={item.amount} className={"amount"} data-id={item.id} onChange={handleSelected}>
                                {getOptions(item)}
                                {item.amount > 10 ? (
                                    <option value={item.amount}>{item.amount}</option>
                                ) : ""}
                            </select>
                            <p className={"article-total"}>{numberFormatSummary((item.price * item.amount))}</p>
                            <FontAwesomeIcon icon={faTrash} className={"delete"} data-id={item.id} onClick={handleTrashClick}/>
                        </div>
                    ))}
                    <button onClick={handleRemoveAll} className={"remove-all-btn"}>Remove all</button>
                </div>
                <div className="right-col">
                    {!auth ? (
                        <div className={"user"}>
                            <h1>Account</h1>
                            <p>Please login or create an account to continue to payment</p>
                            <Link to={"/account/login"} className={"button-action border-radius"}>Login</Link>
                            <div><p>or</p></div>
                            <Link to={"/account/register"} className={"button-action border-radius"}>Register</Link>
                        </div>
                    ) : (
                        !addressDefined || changingAddress ? (
                            <div className={"address"}>
                                <h1>Delivery address</h1>
                                {error !== "" ? <p className={"text-red-500 py-2"}>{error}</p> : ""}
                                <AddressComponent mode={changingAddress ? "update" : "new"} savedCallback={setSavedAddress} resetSavedCallback={setChangingAddress} className={"cart"} />
                            </div>) : (
                            <div className={"address"}>
                                <h1>Delivery address</h1>
                                {(address !== undefined) ? (
                                    <div className={"p-2 pt-0 flex flex-col"}>
                                        <p>{address["streetName"]} {address["streetNumber"]}</p>
                                        <p>{address["city"]}</p>
                                        <p>{address["province"]}</p>
                                        <p>{address["zipCode"]}</p>
                                        <p>{address["country"]}</p>
                                        <button className={"button-action border-radius self-center"} onClick={() => setChangingAddress(true)}>Change address</button>
                                    </div>
                                ) : "Something went wrong"}
                            </div>
                        )
                    )}

                    <div className={"summary"}>
                        <h1>Summary</h1>
                        <div className="summary-articles">
                            <p>Articles <small>({cartAmount})</small></p><p>{numberFormatSummary(total)}</p>
                        </div>
                        <div className="summary-shipping">
                            <p>Shipping cost</p><p>{numberFormatSummary(shippingCost)}</p>
                        </div>
                        <div><input type="text" onChange={(e) => setCode(e.currentTarget.value)} placeholder={"Coupon code"}/><button onClick={addCoupon} className={"button-add-coupon"}>Add Coupon</button></div>
                        <hr/>
                        <div className={"summary-total"}>
                            <p>Total</p><p>{numberFormatSummary(total + shippingCost)}</p>
                        </div>
                        {cartItems.length > 0 ? (
                            <button className={"button-action border-radius"} onClick={sendOrder} disabled={buttonDisabled}>Continue to payment</button>
                        ) : ""}
                        <div className={"payment-method-icons"}>
                            <FontAwesomeIcon icon={faIdeal} />
                            <FontAwesomeIcon icon={faPaypal} />
                            <FontAwesomeIcon icon={faCcVisa} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;