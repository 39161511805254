import {createSlice} from "@reduxjs/toolkit";

interface LoggedInState {
    value: boolean
}

const initialState: LoggedInState = {
    value: false
}

const loggedInSlice = createSlice({
    name: "loggedIn",
    initialState,
    reducers: {
        signIn: (state) => {
            state.value = true;
        },
        signOut: (state) => {
            state.value = false;
        }
    }
});

export const { signIn, signOut } = loggedInSlice.actions;

export default loggedInSlice.reducer;