import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CustomJwtPayload} from "../../Routing";

interface UserState {
    value: CustomJwtPayload | null
}

const initialState: UserState = {
    value: null
}

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserData: (state, action: PayloadAction<CustomJwtPayload>) => {
            state.value = action.payload;
        },
        clearUserData: (state) => {
            state.value = null;
        }
    }
});

export const { setUserData, clearUserData } = userSlice.actions;

export default userSlice.reducer;