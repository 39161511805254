"use client"
import {Link, useNavigate, useParams} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import {useDispatch,} from "react-redux";
import {signIn, signOut} from "../../../state/user/loggedInSlice";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "sonner";

type Inputs = {
    email: string,
    password: string
};

export default function Verify() {
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        axios.post(`https://shopapi.deagarys.com/authentication/verify?verificationToken=${id}`)
            .then((res) => {
                if (res.status === 200) {
                    toast.success('Account verified, you can now login!');
                    navigate("/account/login", {replace: true});
                }
            })
            .catch((e) => {
            })
    }, [])


    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper login-wrapper">
            <div className={"login-modal max-w-l border-radius"}>
                <h1>Token invalid</h1>
            </div>
        </div>
    );
}
