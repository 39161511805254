import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./Account.css";
import {useSelector} from "react-redux";
import {RootState} from "../../state/store";
import axios from "axios";
import Cookies from "universal-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagsShopping, faUser} from "@fortawesome/pro-regular-svg-icons";
import AddressComponent from "../../components/Address/Address";

interface Address {
    streetNumber: string,
    streetName: string,
    city: string,
    province: string,
    country: string,
    zipCode: string
}

interface UserSelf {
    id: string,
    email: string,
    username: string,
    firstName: string,
    lastName: string
}

function Account() {
    const user = useSelector((state: RootState) => state.userPersisted.user.value);
    const [address, setAddress] = useState<Address>();
    const [userSelf, setUserSelf] = useState<UserSelf>();
    const [changingAddress, setChangingAddress] = useState<boolean>();
    const [savedAddress, setSavedAddress] = useState(false);
    const navigate = useNavigate();
    const cookies = new Cookies();

    useEffect(() => {
        setChangingAddress(false);
        setSavedAddress(false);
        getDeliveryAddress();
        getUserSelf();
    }, [savedAddress, navigate]);

    function getUserSelf() {
        axios({
            method: "get",
            url: "https://shopapi.deagarys.com/api/Users/Self",
            headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data) {
                    setUserSelf(response.data);
                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    function getDeliveryAddress() {
        axios({
            method: "get",
            url: "https://shopapi.deagarys.com/api/DeliveryAddress",
            headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data) {
                    setAddress(response.data);
                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    function changeAddress() {
        setChangingAddress(true)
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper">
            <div className={"account-wrapper max-w-l"}>
                <div>
                    <ul>
                        <li><Link to={"/account"}><FontAwesomeIcon icon={faUser} /> - Account</Link></li>
                        <li><Link to={"/account/orders"}><FontAwesomeIcon icon={faBagsShopping} /> - Orders</Link></li>
                    </ul>
                </div>
                <div>
                    <h1>Welcome, <b>{user ? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] : ""}!</b></h1>
                    <hr className={"my-4"}/>
                    <h2>Personal Information</h2>
                    <div className={"p-5 bg-white personal-information"}>
                        <div className="row">
                            <p>First name:</p>
                            <p>{userSelf?.firstName}</p>
                        </div>
                        <div className="row">
                            <p>Last name:</p>
                            <p>{userSelf?.lastName}</p>
                        </div>
                        <div className="row">
                            <p>Email:</p>
                            <p>{userSelf?.email}</p>
                        </div>
                        <div className="row">
                            <p>Username:</p>
                            <p>{userSelf?.username}</p>
                        </div>
                    </div>
                    <hr className={"my-4"}/>
                    <h2>Delivery address:</h2>
                    {address && !changingAddress ? (
                        <div className={"p-5 bg-white mt-2"}>
                            <p>{address["streetName"]} {address["streetNumber"]}</p>
                            <p>{address["city"]}</p>
                            <p>{address["province"]}</p>
                            <p>{address["zipCode"]}</p>
                            <p>{address["country"]}</p>
                            <button className={"button-action w-60 border-radius mt-5"} onClick={changeAddress}>Change address</button>
                        </div>
                    ) :
                        <AddressComponent mode={address ? "update" : "new"} savedCallback={setSavedAddress} className={"account p-5"} />
                    }
                </div>
            </div>
        </div>
    );
}

export default Account;