import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/pro-regular-svg-icons";
import CategoryType from "../../Types/CategoryType";

function CategoriesHome() {
    const [data, setData] = useState<CategoryType[]>([]);

    useEffect(() => {
        axios.get(`https://shopapi.deagarys.com/api/Categories`)
            .then(res => {
                const result = res.data;
                setData( result );
            });
    }, []);

    return (
        <div className={"categories-navigation-wrapper"}>
            {data.length <= 0 ? (<FontAwesomeIcon className={"loader-icon"} icon={faSpinner} spin />) : (
            <div className={"categories-navigation"}>
                {data.map((category, index) => (
                    <div key={index} className={"category-div"}>
                        <Link to={`/category/${category.prettyLink}`}>
                            <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(category.imagePath)}`} alt={"category"} className={"category-image border-radius"} width={1000} height={1000} />
                            <p>{category["name"]}</p>
                        </Link>
                    </div>
                ))}
            </div>
            )}
        </div>
    );
}

export default CategoriesHome;