import React, {useEffect, useState} from "react";
import {faCartShopping, faEye, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductType from "../../Types/ProductType";
import axios from "axios";
import {numberFormat} from "../../scripts/NumberFormat";
import { Link } from "react-router-dom";
import {ProductRegion} from "../../Types/ProductRegion";

async function GetProducts(pageNumber: number, maxItemsPerPage: number) {
    let data: ProductType[] = [];

    await axios.get(`https://shopapi.deagarys.com/api/products/NewStock?PageNumber=${pageNumber}&MaxItemsPerPage=${maxItemsPerPage}`)
        .then(res => {
            data = res.data;
        });

    return data;
}

function ProductsHome(props: {
    maxPerPage: number
}) {
    const [data, setData] = useState<ProductType[] | []>([]);

    useEffect(() => {
        let getProducts = async () => {
            return await GetProducts(1, props.maxPerPage);
        }

        getProducts().then((result) => {
            setData(result);
        })

    }, []);

    return (
        <div className={"products-wrapper"}>
            {data.length <= 0 ? (<FontAwesomeIcon className={"loader-icon"} icon={faSpinner} spin />) : (
                <div className={"products-grid"}>
                    {data.map((category, index) => (
                        <Link to={`/product/${category.prettyLink}`} key={index}>
                            <div key={index} className={"product-div border-radius"}>
                                <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(category.imagePath)}`} alt={"category"} className={"product-image border-radius"} width={1000} height={1000} />
                                <p className={"product-title"}>{category.name}</p>
                                <p className={"product-subcat"}>{category.name}</p>
                                <p className={"product-region"}>{ProductRegion[category.region]}</p>
                                <p className={"product-price"}>€{numberFormat(category.price)}</p>
                                <button className={"border-radius button-action"}><FontAwesomeIcon icon={faCartShopping} /> Add to cart</button>
                            </div>
                        </Link>
                    ))}
                    <Link to={"/category/pokemon"} className={"button-action border-radius button-see-more"}><FontAwesomeIcon icon={faEye} className={"mr-2"} />See more</Link>
                </div>
            )}
        </div>
    );
}

export default ProductsHome;