import React, {useEffect, useState} from "react";
import {numberFormat} from "../../../scripts/NumberFormat";
import axios from "axios";
import CategoryType from "../../../Types/CategoryType";
import UpdateCategoryModal from "./UpdateCategoryModal";
import CustomModal from "../../../components/CustomModal";
import {SubmitHandler, useForm} from "react-hook-form";
import Cookies from "universal-cookie";

type InputsUpdate = {
    categoryName: string,
    prettyLink: string,
    image: string,
};
function CategorysDashboardOverview() {
    const [data, setData] = useState<CategoryType[]>([]);
    const [category, setCategory] = useState<CategoryType>();
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [categoryId, setCategoryId] = useState<string | undefined>("");

    const [modalIsOpen, setIsOpen] = React.useState(false);
    const {register, resetField, control, handleSubmit, setError, formState: { errors, isSubmitting}} = useForm<InputsUpdate>();
    const [productDeleted, setProductDeleted] = useState<boolean>(false);
    const [modalTitle, setModalTitle] = useState("");

    useEffect(() => {
        axios.get(`https://shopapi.deagarys.com/api/Categories`)
            .then(res => {
                const result = res.data;
                setData(result);
            });

        setProductDeleted(false);
    }, [category, modalIsOpen, openEditModal, productDeleted]);

    function editCategory(e: React.MouseEvent<HTMLButtonElement>) {
        axios.get(`https://shopapi.deagarys.com/api/Categories/${e.currentTarget.dataset["id"]}`)
            .then(res => {
                resetField("categoryName");
                resetField("prettyLink");
                resetField("image");
                setModalTitle(res.data.name)
                setOpenEditModal(true);
                setCategory(res.data);
            }).catch((e) => e);
    }

    function deleteCategory(e: React.MouseEvent<HTMLButtonElement>) {
        const cookies = new Cookies();
        axios.delete(`https://shopapi.deagarys.com/api/Categories/${e.currentTarget.dataset["id"]}`, { headers: {"Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`} })
            .then(res => {
                setProductDeleted(true);
            }).catch((e) => e);
    }

    const onSubmit: SubmitHandler<InputsUpdate> = async (dataUpdate) => {
        if (category === undefined)
            return;

        var formData = new FormData();
        formData.append("Id", category.id);
        formData.append("Name", dataUpdate.categoryName);
        formData.append("PrettyLink", dataUpdate.prettyLink);

        if (dataUpdate.image.length !== 0) {
            formData.append("Image", dataUpdate.image[0]);
        }

        const cookies = new Cookies();
        await axios({
            method: "put",
            url: `https://shopapi.deagarys.com/api/Categories/${category?.id}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data", "Authorization" : `Bearer ${cookies.get("_jwt").jwt.accessToken}`},
        })
            .then(function (response) {
                if (response.data > 0) {
                    closeModal();
                }
            })
            .catch(function (response) {
                //handle error
            });
    }

    function closeModal() {
        setOpenEditModal(false);
    }

    return (
        <div className={"categories-wrapper w-full"}>
            <div className={"categories-table"}>
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className={"ext-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"}>
                    <tr>
                        <th>Name</th>
                        <th>Pretty Link</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((data, index) => (
                        <tr key={index} className={"odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"}>
                            <td className={""}>{data.name}</td>
                            <td className={""}>{data.prettyLink}</td>
                            <td className={"flex flex-row gap-2"}>
                                <button className={"border-radius button-action"} data-id={data.id} onClick={editCategory}>Edit</button>
                                <button className={"border-radius button-action button-delete"} data-id={data.id} onClick={deleteCategory}>Delete</button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <CustomModal isOpen={openEditModal} title={modalTitle} modalClose={closeModal}>
                <form onSubmit={handleSubmit(onSubmit)} className={`form-control`}>
                    <div className={""}>
                        <input defaultValue={category?.name} {...register("categoryName", {
                            required: "Name is required"
                        })} className={`border-radius ${errors.categoryName ? "border-red" : ""}`} placeholder={"Product name"}/>
                    </div>
                    {errors.categoryName && (<span className={"text-red-500"}>{errors.categoryName.message}</span>)}
                    <div className={""}>
                        <input defaultValue={category?.prettyLink} {...register("prettyLink", {
                            required: "prettyLink is required"
                        })} className={`border-radius ${errors.prettyLink ? "border-red" : ""}`} placeholder={"prettyLink name"}/>
                    </div>
                    {errors.prettyLink && (<span className={"text-red-500"}>{errors.prettyLink.message}</span>)}
                    <div className={""}>
                        <input {...register("image")} type={"file"} className={`border-radius ${errors.image ? "border-red" : ""}`} placeholder={"Product image"}/>
                    </div>
                    {errors.image && (<span className={"text-red-500"}>{errors.image.message}</span>)}
                    <button className={"border-radius button-action"} type={"submit"} disabled={isSubmitting}>{isSubmitting ? "Updating..." : "Update Category"}</button>
                </form>
            </CustomModal>
        </div>
    );
}

export default CategorysDashboardOverview;