import React, {useEffect, useState} from "react";
import {faArrowLeft, faArrowRight, faCartShopping, faSpinner} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductType from "../../Types/ProductType";
import axios from "axios";
import {numberFormat} from "../../scripts/NumberFormat";
import {Link, useNavigate} from "react-router-dom";
import {ProductRegion} from "../../Types/ProductRegion";

async function GetProducts(id: string |  undefined, pageNumber: number, maxItemsPerPage: number) {
    let data: ProductsPagination = {
        maxPages: 1,
        products: []
    };
    var formData = new FormData();

    formData.append("PageNumber", pageNumber.toString());
    formData.append("MaxItemsPerPage", maxItemsPerPage.toString());

    await axios({
        method: "get",
        url: `https://shopapi.deagarys.com/api/products/GetExcept/${id}/${maxItemsPerPage}/${pageNumber}`,
    })
        .then(function (res) {
            data = res.data;
            return data;
        })
        .catch(function (res) {
            //handle error
        });

    return data;
}

interface ProductsPagination {
    maxPages: number,
    products: ProductType[]
}

function ProductsRecommended(props: {
    maxPerPage: number,
    exceptId: string | undefined
}) {
    const [data, setData] = useState<ProductsPagination>();
    const [page, setPage] = useState(1);
    const navigate = useNavigate();

    useEffect(() => {
        let getProducts = async () => {
            return await GetProducts(props.exceptId, page, props.maxPerPage);
        }

        getProducts().then((result) => {
            setData(result);
        })

    }, [page, navigate]);

    function previousPage() {
        if (page === 1)
            return;

        setPage(page-1)
    }

    function nextPage() {
        if (page === data?.maxPages)
            return;

        setPage(page+1)
    }

    return (
        <div className={"products-wrapper"}>
            {data !== undefined && data?.products.length <= 0 ? (<FontAwesomeIcon className={"loader-icon"} icon={faSpinner} spin />) : (
                <div className={"products-grid"}>
                    {data?.products.map((category, index) => (
                        <Link to={`/product/${category.prettyLink}`} key={index}>
                            <div key={index} className={"product-div border-radius"}>
                                <img src={`https://shopapi.deagarys.com/api/images/${encodeURIComponent(category.imagePath)}`} alt={"category"} className={"product-image border-radius"} width={1000} height={1000} />
                                <p className={"product-title"}>{category.name}</p>
                                <p className={"product-subcat"}>{category.name}</p>
                                <p className={"product-region"}>{ProductRegion[category.region]}</p>
                                <p className={"product-price"}>€{numberFormat(category.price)}</p>
                                <button className={"border-radius button-action"}><FontAwesomeIcon icon={faCartShopping} /> Add to cart</button>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
            <div className={"previous-page"} onClick={previousPage}><FontAwesomeIcon icon={faArrowLeft} style={{color: "#FFFFFF",}} /></div>
            <div className={"next-page"} onClick={nextPage}><FontAwesomeIcon icon={faArrowRight} style={{color: "#FFFFFF",}} /></div>
        </div>
    );
}

export default ProductsRecommended;