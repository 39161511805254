import React from "react";
import Cookies from "universal-cookie";
import CartCookieType from "../../Types/CartCookieType";
import {toast} from "sonner";

export function addToCart(e: React.MouseEvent<HTMLElement>, stock: number | undefined) {
    const productId = e.currentTarget.dataset["id"];

    const cookies = new Cookies();
    let cartCookie = cookies.get("cartCookie");

    if (cartCookie === undefined || cartCookie === "") {
        let newCartCookie: CartCookieType[] = [{id: productId, amount: 1}];
        cookies.set("cartCookie", encodeURI(JSON.stringify(newCartCookie)), {secure: true, path: "/"});
        return;
    }

    let cartCookieObject = JSON.parse(decodeURI(cartCookie));
    let productExistsInCart = cartCookieObject.find((element: CartCookieType) => {
        return element.id === productId
    });

    // add message already is in cart
    if (productExistsInCart) {
        toast.error('Product already is in cart');
        return;

        // if (stock && ((productExistsInCart.amount * 1) + 1) > stock)
        //     return;
        //
        // productExistsInCart.amount = (productExistsInCart.amount * 1) + 1;
    } else {
        cartCookieObject.push({id: productId, amount: 1});
    }

    toast.success('Product added to cart');
    cookies.set("cartCookie", encodeURI(JSON.stringify(cartCookieObject)), {secure: true, path: "/"});
}