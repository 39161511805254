import React from 'react';
import '../App.css';
import CategoriesHome from "../components/Home/CategoriesHome";
import ProductsHome from '../components/Home/ProductsHome';

function Home() {
  return (
      <div className="flex min-h-screen flex-col items-center main-wrapper">
          <div className={"hero-image"}>
              <div className={"hero-text"}>
                  <h1>Nerdy Relics</h1>
                  <p>At Nerdy Relics, we share your passion for collecting and trading cards. Our webshop specializes in trading card games (TCGs), with a primary focus on Pokémon cards. Whether you're a seasoned collector or just starting out, we have something for everyone.</p>
              </div>
          </div>
          <div className={"homepage-wrapper max-w-l"}>
              <div className={"separator-text"}><span></span><p>Shop by Category</p><span></span></div>
              <CategoriesHome />
              <div className={"separator-text"}><span></span><p>New in stock</p><span></span></div>
              <ProductsHome maxPerPage={9} />
          </div>
      </div>
  );
}

export default Home;