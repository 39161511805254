import React, {useEffect, useState} from 'react';
import Sidebar from "../../../components/Navbar/Sidebar";
import "../Admin.css";
import SubCategoriesDashboardOverview from './SubCategoriesDashboardOverview';
import AddSubCategoryModal from "./AddSubCategoryModal";

type Inputs = {
    productName: string,
    prettyLink: string,
    price: number,
    stock: number,
    image: string,
    subCategoryId: OptionsType[]
};

interface OptionsType {
    value: string,
    label: string
}

interface SubCategoryIds {
    subCategoryId: string
}

function SubCategoriesDashboard() {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    useEffect(() => {

    }, [modalIsOpen]);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <div className="flex min-h-screen flex-col items-center main-wrapper main-wrapper-admin">
            <Sidebar />
            <div className={"admin-dashboard-wrapper"}>
                <button onClick={openModal} className={"button-action"}>Add Sub Category</button>
                <SubCategoriesDashboardOverview  />
                <AddSubCategoryModal modalClose={closeModal} isOpen={modalIsOpen} handleClassName={"subcategories-modal"} />
            </div>
        </div>
    );
}

export default SubCategoriesDashboard;